@keyframes cube {
    from {
        transform: scale(0) rotate(0deg) translate(-50%, -50%);
        opacity: 1;
    }
    to {
        transform: scale(20) rotate(960deg) translate(-50%, -50%);
        opacity: 0;
    }
}

.background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    margin: 0;
    background: #0040C1;
    overflow: hidden;
    z-index: -1;
    padding-top: 4em;
    overflow-y: hidden;
    overflow-x: hidden;
}
.background li {
    position: absolute;
    top: 80vh;
    left: 45vw;
    width: 10px;
    height: 10px;
    border: solid 1px #0039ad;
    color: transparent;
    transform-origin: top left;
    transform: scale(0) rotate(0deg) translate(-50%, -50%);
    animation: cube 7s ease-in forwards infinite;
}
undefined
.background li:nth-child(0) {
    animation-delay: 0s;
    left: 59vw;
    top: 8vh;
}

.background li:nth-child(1) {
    animation-delay: 2s;
    left: 14vw;
    top: 26vh;
    border-color: #0046d4;
}

.background li:nth-child(2) {
    animation-delay: 4s;
    left: 66vw;
    top: 56vh;
}

.background li:nth-child(3) {
    animation-delay: 6s;
    left: 35vw;
    top: 74vh;
    border-color: #0046d4;
}

.background li:nth-child(4) {
    animation-delay: 8s;
    left: 32vw;
    top: 33vh;
}

.background li:nth-child(5) {
    animation-delay: 10s;
    left: 94vw;
    top: 91vh;
    border-color: #0046d4;
}

.resume-options {
    width: 75vw;
    margin: auto;
}

.resume-body {
    max-height: 60vh;
    overflow-y: scroll;
}
@media only screen and (max-width: 600px) {

    .resume-options {
        width: 80vw;
        margin: 0;
    }
    .resume-body {
        max-height: 55vh;
    }

}

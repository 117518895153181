html {
    overflow: hidden;
}
.body {
    margin: 0;
    font-size: 1.5em;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif !important;
    color: #ffffff !important;
    height: 100vh !important;
    perspective: 1px !important;
    transform-style: preserve-3d !important;
    overflow-x:hidden !important;
    overflow-y:auto !important;
}
.parallax-wrapper {
    width: 100vw;
    height: 100vh;
    padding-top:5vh;
    box-sizing: border-box;
    transform-style: preserve-3d;
}
.parallax-wrapper::before {
    content:"";
    width: 100vw;
    height: 100vh;
    top:0;
    left:0;
    background-image: url("../../assets/trianglify.png");
    position: absolute;
    z-index: -1;
    transform:translateZ(-1px) scale(2);
    background-repeat: no-repeat;
}
.regular-wrapper {
    width: 100vw;
    height:100vh;
    padding-top:20vh;
    background-image: url("../../assets/trianglify2.png");
    z-index: 2;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;

}
.parallax-wrapper2 {
    width: 100vw;
    height: 100vh;
    padding-top:5vh;
    box-sizing: border-box;
    transform-style: preserve-3d;
}
.parallax-wrapper2::before {
    content:"";
    width: 100vw;
    height: 100vh;
    top:0;
    left:0;
    background-image: url("../../assets/trianglify3.png");
    position: absolute;
    z-index: -1;
    transform:translateZ(-1px) scale(2);
    background-repeat: no-repeat;
}
.regular-wrapper2 {
    width: 100vw;
    height:100vh;
    padding-top:20vh;
    background-image: url("../../assets/trianglify2.png");
    z-index: 2;
    position: relative;
    background-repeat: no-repeat;

}
.texas-parallax-wrapper {
     width: 100vw;
     height: 100vh;
     padding-top:5vh;
     box-sizing: border-box;
     transform-style: preserve-3d;
 }
.texas-parallax-wrapper::before {
    content:"";
    width: 100vw;
    height: 100vh;
    top:0;
    left:0;
    background-image: url("../../assets/Texas.png");
    position: absolute;
    z-index: -1;
    transform:translateZ(-1px) scale(2);
    background-repeat: no-repeat;
    background-size: cover;
}
.mizzou-wrapper {
    width: 100vw;
    height:100vh;
    padding-top:20vh;
    background-image: url("../../assets/mizzou.png");
    z-index: 2;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
}
.content {
    margin: 0 auto;
    padding: 50px;
    width: 50vw;
    background: dimgray;
    max-height: 60vh;
    overflow-y: scroll;
}
.rounded{
    border: 2px;
    border-radius: 10px;
    -webkit-box-shadow: 0 0 10px rgb(0,0,0);
    -moz-box-shadow: 0 0 10px rgb(0,0,0);
}
.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.welcome-pic{
    width: 25rem;
    height: 35rem;
}
.welcome-pic-wide{
    width: 38rem;
    height: 35rem;
}
.welcome-header {
    color: dodgerblue;
    font-size: 3rem;
    display: flex;
    justify-content: center;
}

.created {
    display: flex;
    justify-content: center;
    font-size: .75rem;
}
.scroller {
    width: 15%;
    height: 15%;
    margin-left: 85vw;
    margin-top: -22vh;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scroll::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scroll {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

@media only screen and (max-width: 600px) {
    .body {
        font-size: 1rem;
    }
    .created {
        font-size: .5rem;
    }
    .welcome-header {
        font-size: 1.25rem;
    }
    .welcome-pic-wide {
        width: 100%;
        height: 75%;
    }
    .content {
        min-width: 90vw;
        max-height: 80vh;
    }
    .mizzou-wrapper {
        background-position: center;
    }
    .scroller {
        width: 20%;
        height: 15%;
        margin-left: 40vw;
        margin-top: .024vh;
    }
}

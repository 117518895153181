.projects-tabs {
    background-color: white;
    margin: auto;
    justify-content: center;
    font-size: 1.5em;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    width: 50vw;
    border: 2px;
    border-radius: 10px;
    -webkit-box-shadow: 0 0 10px rgb(0,0,0);
    -moz-box-shadow: 0 0 10px rgb(0,0,0);
}

@media only screen and (max-width: 600px) {

    .projects-tabs {
        font-size: .7rem;
        width: 95vw;
    }
}

.resume-img-container {
    display: flex;
    justify-content: center;
}
.resume-img {
    height: 24em;
    width: 18em;
    -webkit-box-shadow: 0 0 10px rgb(0,0,0);
    -moz-box-shadow: 0 0 10px rgb(0,0,0);
}
.resume-btn-container {
    margin-top: 1em;
    display: flex;
    justify-content: center;
}

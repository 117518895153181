.events-container {
    width: 75vw;
    background-color: slategray;
    border-radius: 10px;
    -webkit-box-shadow: 0 0 10px rgb(0,0,0);
    -moz-box-shadow: 0 0 10px rgb(0,0,0);
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    overflow-y: scroll;
    max-height: 70vh;
    overflow-x: hidden;

}
.logo {
    display: flex;
    justify-content: center;
    width: 100%;
}

.iframe-container {
    display: flex;
    justify-content: center;
}

.events-description {
    margin: 1em;
    font-family: 'Montserrat', sans-serif;
    color: white;
}

.buttons {
    display: flex;
    justify-content: center;
    margin-top: 1em;
    padding-bottom: 1em;
}

@media only screen and (max-width: 600px) {

    .events-container {
        max-height: 60vh;
        width: 90vw;
    }
}

.ridsi-container {
    width: 75vw;
    background-color: white;
    border-radius: 10px;
    -webkit-box-shadow: 0 0 10px rgb(0,0,0);
    -moz-box-shadow: 0 0 10px rgb(0,0,0);
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    overflow-y: scroll;
    max-height: 70vh;

}
.logo {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: -2em;
    overflow-x: hidden;

}

.iframe-container {
    margin-top: -2em;
    display: flex;
    justify-content: center;
}

.ridsi-description {
    margin: 1em;
    font-family: 'Montserrat', sans-serif;
    color: black;
}

.buttons {
    display: flex;
    justify-content: center;
    margin-top: 1em;
    padding-bottom: 1em;
}

.button-space {
    margin-right: 1em;
    margin-left: 1em;
}

@media only screen and (max-width: 600px) {

    .ridsi-container {
        max-height: 60vh;
        width: 90vw;
    }
}

